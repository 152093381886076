import { VuexModule, Module, Action } from 'vuex-class-modules';
import Axios from 'axios';
import store from '@/store';

@Module({ generateMutationSetters: true })
export class InvalidationReasonModule extends VuexModule {
    invalidationnReasons: { name: string }[] = [];

    @Action
    async index() {
        const { data } = await Axios.get<{ name: string }[]>(
            'invalidation-reason'
        );
        this.invalidationnReasons = data;
    }
}
export const invalidationnReasonModule = new InvalidationReasonModule({
    store,
    name: 'invalidationnReason'
});
